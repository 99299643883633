<template>
  <div v-if="!unMountComponent">
    <banner :list="list"></banner>
    <menu-tab
      :menu="cnclassArr"
      v-if="cnclassArr && cnclassArr.length > 0"
      :swiper="true"
      :slidesPerView="2"
      :initIndex="index"
      @change="selectMenu" 
    ></menu-tab>
    <list
      url="/M/Server/GetDisLastke"
      :show="currentMenuItem == item.dictValue"
      v-for="(item, index) in courseclasslist"
      :key="item.dictKey"
      :params="{ fenlei: item.dictKey }"
      :filterFunc="filterFunc"
      :typeIndex="index"
    >
      <template v-slot:default="data">
        <class-item :course="data"></class-item>
      </template>
    </list>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, nextTick } from "vue";

import { useRoute, onBeforeRouteLeave } from "vue-router";

import api from "@/api/axios";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import ClassItem from "@/components/common/ClassItem.vue";

import MenuTab from "@/components/common/MenuTab.vue";

export default defineComponent({
  name: "Course",
  components: {
    banner,
    list,
    ClassItem,
    MenuTab,
  },
  setup() {
    const courseclasslist = ref<object>([]);
    const list = ref(null);
    const classlist = ref<object>([]);
    const currentMenuItem = ref(0);
    const route = useRoute();
    const type = route.query.type;
    const cnclassArr = reactive<any>([]);
    const index = ref(0);
    console.log(type)
    function switchTab(type: string | null, target: any) {
      if( !type ){
        return false
      }
      for (let i = 0; i < target.length; i++) {
        const element = target[i];
        if (type == element.dictKey) {
          index.value = i;
          currentMenuItem.value = element.dictValue
          return true;
        }
      }
    }
    api
      .get("/M/Discover/Course")
      .then((res) => {
        const data = res.data.obj;
        courseclasslist.value = data.courseclasslist;
        list.value = data.indexSlides;
        classlist.value = data.classlist;
        currentMenuItem.value = data.courseclasslist[0].dictValue;
        console.log(currentMenuItem.value)
        data.courseclasslist.map((item: any) => {
          cnclassArr.push(item.dictValue);
        });
        switchTab(type as string, courseclasslist.value)
      })
      .catch((err) => {
        console.log(err);
      });

    function selectMenu(item: any) {
      currentMenuItem.value = item;
    }

    function filterFunc(data: any) {
      if (data.fenlei == 228) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }



    const unMountComponent = ref(false);
    onBeforeRouteLeave((to, from) => {
      if (
        to.name != "videodetail" &&
        to.name != "livedetail" &&
        to.name != "Personal"
      ) {
        // unMountComponent.value = true;
        nextTick(() => {
          // unMountComponent.value = false;
        });
      }
      return true;
    });

    return {
      list,
      courseclasslist,
      cnclassArr,
      classlist,
      currentMenuItem,
      selectMenu,
      filterFunc,
      unMountComponent,
      index
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
